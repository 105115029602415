<template>
  <div class="boxPage">
    <div class="top">
      <div class="top_main">
        <div class="pic">
          <div class="pic_top">
            <div class="zbname">{{ classrow.name }}</div>
            <!-- <div class="zbtime"><span></span> 直播时间：{{ classrow.time }}</div> -->
          </div>
          <div class="pimg">
            <img :src="classrow.image" alt="" />
            <!-- 弹框 -->
            <div v-if="ks" class="dio">
              <div class="dtop">
                <span><img src="@/assets/img/markcamp/zz.png" alt="" /></span> 直播未开始
              </div>
              <div class="dtime">
                直播时间：<span>{{ time }}</span>
              </div>
              <!-- <div class="dbtn">进入直播</div> -->
            </div>
            <div v-if="kss" class="dios">
              <div class="dtop">
                <span><img src="@/assets/img/markcamp/zzb.png" alt="" /></span>{{ rowitem.isLive == 3 ? '回放已生成' : '直播已开始'
                }}
              </div>
              <div @click="goclass()" class="dbtn">{{ rowitem.isLive == 3 ? '观看回放' : '进入直播' }}</div>
            </div>
          </div>
        </div>
        <div class="classpit">
          <div class="classpit_top">
            <div class="cname">{{ classrow.name }}</div>
            <div class="cdetil">{{ classrow.introduce }}</div>
            <div class="cstupeo">
              <span><img src="@/assets/img/markcamp/r.png" alt="" /> </span>
              {{ allmsg.studentNumber }}人已报名
            </div>
          </div>
          <div class="classpit_btm">
            <div class="hjml">环节目录<i class="iconfont icon-a-zu335"></i></div>
            <div class="hjlist">
              <div @click="godetail(item)" :class="item.isclick ? 'listitem' : 'listitem listitems'"
                v-for="(item, index) in classrow.tasks" :key="index">
                <div class="ic">
                  <img v-if="item.isLive == 0" src="@/assets/img/markcamp/s.png" alt="" />
                  <img v-else src="@/assets/img/markcamp/ss.png" alt="" />
                </div>
                <div class="f">
                  <span>{{ index < 10 ? '0' + (index + 1) : index + 1 }}</span>
                      <span class="yc">{{ item.name }}</span>
                      <span class="hf" v-if="item.isLive == 3">回放</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btm">
      <div class="bhj">环节目录</div>
      <div class="bhjlist">
        <div @click="godetail(item)" :class="item.isclick ? 'bl' : 'bl bls'" v-for="(item, index) in classrow.tasks"
          :key="index">
          <div v-if="item.isclick" class="sj"><img src="@/assets/img/markcamp/sj.png" alt="" /></div>
          <div class="ic">
            <img v-if="item.isLive == 0" src="@/assets/img/markcamp/s.png" alt="" />
            <img v-else src="@/assets/img/markcamp/z.png" alt="" />
          </div>
          <div class="yy">
            <span>{{ index < 10 ? '0' + (index + 1) : index + 1 }}</span> {{ item.name }}
          </div>
          <div @click="goclass(item)" v-if="item.isLive == 1 && item.isclick" class="gk">观看</div>
          <div @click="goclass(item)" v-if="item.isLive == 3 && item.isclick" class="gkhf">观看回放</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addLog, getAllDetail } from '@/api/markcamp'
import { getInfo } from '@/api/cookies'
import Vue from 'vue'
export default {
  data() {
    return {
      userInfo: {},
      list: [],
      name: '',
      time: '',
      ks: false,
      kss: false,
      rowitem: {},
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.trainingId = this.$route.query.id
    this.tid = this.$route.query.tid
    getAllDetail({
      userid: this.userInfo ? this.userInfo.id : undefined,
      trainingId: this.trainingId ? this.trainingId : undefined,
    }).then((res) => {
      for (let items of res.data.links) {
        if (items.id == this.tid) {
          this.classrow = items
        }
      }
      this.allmsg = res.data
      this.classrow.tasks.forEach((e) => {
        e.isclick = false
      })
      this.classrow.tasks[0].isclick = true
      this.$forceUpdate()
    })
    // this.classrow = this.$route.query.classrow
    // this.allmsg = this.$route.query.allmsg
    // this.classrow = JSON.parse(localStorage.getItem('classrow'))
    // this.allmsg = JSON.parse(localStorage.getItem('allmsg'))

  },
  methods: {
    // getList() {
    //   getAllDetail({
    //     userid: this.userInfo ? this.userInfo.id : undefined,
    //     trainingId: this.trainingId ? this.trainingId : undefined,
    //   }).then((res) => {
    //     for (let items of res.data.links) {
    //       if (items.id == this.tid) {
    //         this.classrow = items
    //       }
    //     }

    //     this.allmsg = res.data
    //     this.name = this.classrow.tasks[0].name
    //     this.time = this.classrow.tasks[0].startTime
    //     this.classrow.tasks.forEach((e) => {
    //       e.isclick = false
    //     })
    //     this.classrow.tasks[0].isclick = true
    //   })
    //   this.$forceUpdate()
    // },
    godetail(item) {
      this.rowitem = item
      this.time = item.startTime
      this.classrow.tasks.forEach((e) => {
        if (item.id == e.id) {
          e.isclick = true
        } else {
          e.isclick = false
        }
      })
      if (item.isLive == 0) {
        this.kss = false
        this.ks = true
      } else if (item.isLive == 2) {
        this.ks = false
        this.kss = false
        this.$message({
          message: '回放生成中',
          type: 'warning',
        })
      } else {
        this.ks = false
        this.kss = true
      }

      this.$forceUpdate()
    },
    goclass(item) {
      if (!this.userInfo) {
        localStorage.setItem('rowitem', '')
        this.$router.push({
          path: '/markcamp/camplist',
        })
      } else {


        if (item) {
          localStorage.setItem('rowitem', JSON.stringify(item))
          addLog({
            studentId: this.userInfo ? this.userInfo.id : undefined,
            campId: this.allmsg.id ? this.allmsg.id : undefined,
            linkId: this.classrow.id ? this.classrow.id : undefined,
            taskId: item.id ? item.id : undefined,
          })
          this.rowitem = item
          window.open(`/markcamp/zbclass?id=${this.trainingId}&tid=${this.tid}&cid=${item.id}`, '_blank')
          // window.open('/markcamp/zbclass', '_blank')
          // this.$router.push({
          //   path: '/markcamp/zbclass',
          //   // query: { rowitem: this.rowitem, classrow: this.classrow },
          // })
        } else {
          localStorage.setItem('rowitem', JSON.stringify(this.rowitem))
          addLog({
            studentId: this.userInfo ? this.userInfo.id : undefined,
            campId: this.allmsg.id ? this.allmsg.id : undefined,
            linkId: this.classrow.id ? this.classrow.id : undefined,
            taskId: this.rowitem.id ? this.rowitem.id : undefined,
          })
          window.open(
            `/markcamp/zbclass?id=${this.trainingId}&tid=${this.tid}&cid=${this.rowitem.id}`,
            '_blank'
          )
          // window.open('/markcamp/zbclass', '_blank')
          // this.$router.push({
          //   path: '/markcamp/zbclass',
          //   // query: { rowitem: this.rowitem, classrow: this.classrow },
          // })
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.boxPage {
  padding-bottom: 195px;
}

.top {
  width: 100%;
  height: 566px;
  background: url('~@/assets/img/markcamp/592849fa9fa08.png');
  background-repeat: no-repeat;

  .top_main {
    width: 1233px;
    margin: 0 auto;
    display: flex;

    .pic {
      width: 827px;
      margin-top: 21px;

      .pic_top {
        display: flex;
        background: #272727;
        height: 39px;
        line-height: 39px;
        justify-content: space-between;

        .zbname {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          margin-left: 18px;
        }

        .zbtime {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          margin-right: 14px;
          display: flex;
          align-items: center;

          span {
            display: block;
            background: #ff7b1b;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            margin-right: 7px;
          }
        }
      }

      .pimg {
        width: 100%;
        height: 480px;
        margin: 0 auto;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .classpit {
      width: 386px;
      margin-left: 20px;
      margin-top: 21px;

      .classpit_top {
        width: 100%;
        height: 189px;
        background: #1c1d1d;
        border-radius: 4px;
        padding: 22px 0 25px 23px;
        position: relative;

        .cname {
          font-size: 22px;
          font-family: MicrosoftYaHei;
          color: #ff7b1b;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 270px;
        }

        .cdetil {
          padding: 8px;
          width: 354px;
          height: 54px;
          line-height: 22px;
          background: #2f2f2f;
          border-radius: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          margin-top: 17px;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;
          display: -webkit-box;

          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // -moz-line-clamp: 2;
          // -moz-box-orient: vertical;
          // -ms-line-clamp: 2;
          // -ms-box-orient: vertical;
        }

        .cstupeo {
          display: flex;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #dfdfdf;
          position: absolute;
          right: 19px;
          bottom: 25px;

          span {
            display: block;
            width: 11px;
            margin-right: 4px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .classpit_btm {
        width: 100%;
        height: 321px;
        background: #1c1d1d;
        border-radius: 4px;
        margin-top: 9px;
        padding-top: 18px;

        .hjml {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #ffffff;

          margin-left: 23px;
        }

        .hjlist {
          width: 370px;
          height: 256px;
          background: #28292b;
          border-radius: 4px;
          margin: 0 auto;
          margin-top: 12px;
          overflow-y: scroll;

          .listitem {
            width: 300px;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #ffffff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            margin-top: 20px;
            margin-left: 11px;
            cursor: pointer;

            .ic {
              width: 20px;
              height: 20px;
              margin-right: 8px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .listitems {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #cacaca;
          }
        }
      }
    }
  }
}

.btm {
  width: 1233px;
  margin: 0 auto;
  margin-top: 27px;

  .bhj {
    width: 827px;
    height: 66px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 66px;
    padding-left: 28px;
  }

  .bhjlist {
    width: 827px;
    background: #ffffff;
    border-radius: 4px;
    padding-bottom: 70px;
    margin-top: 20px;

    .bl {
      width: 749px;
      display: flex;
      align-items: center;
      height: 62px;
      border-bottom: 1px solid #ededed;
      margin: 0 auto;
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      position: relative;
      cursor: pointer;

      .ic {
        width: 20px;
        height: 20px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .bls {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #555555;
    }
  }

  .bl:last-child {
    border-bottom: 0;
  }
}

.sj {
  position: absolute;
  left: -37px;
  width: 10px;
  height: 15px;

  img {
    width: 100%;
    height: 100%;
  }
}

.dio {
  width: 377px;
  height: 149px;
  background: rgba(21, 21, 21, 0.8);
  position: absolute;
  top: 175px;
  left: 217px;

  .dtop {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;

    span {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dtime {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    text-align: center;
    margin-top: 30px;

    span {
      font-size: 18px;
      color: #ff7b1b;
    }
  }

  .dbtn {
    cursor: pointer;
    width: 94px;
    height: 29px;
    background: #2871ff;
    border-radius: 34px;

    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 29px;
    margin: 0 auto;
    text-align: center;
    margin-top: 34px;
  }
}

.dios {
  width: 377px;
  height: 149px;
  background: rgba(21, 21, 21, 0.8);
  position: absolute;
  top: 175px;
  left: 217px;

  .dtop {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;

    span {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dbtn {
    cursor: pointer;
    width: 94px;
    height: 29px;
    background: #2871ff;
    border-radius: 34px;

    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 29px;
    margin: 0 auto;
    text-align: center;
    margin-top: 34px;
  }
}

.gk {
  width: 67px;
  height: 29px;
  line-height: 29px;
  background: #2871ff;
  border-radius: 34px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  text-align: center;
  position: absolute;
  right: 47px;
}

.gkhf {
  width: 77px;
  height: 29px;
  border-radius: 34px;
  border: 1px solid #2871ff;

  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #2871ff;
  line-height: 29px;
  text-align: center;
  position: absolute;
  right: 47px;
}

.hf {
  width: 34px;
  // height: 19px;
  background: #fff1e7;
  border-radius: 2px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff7b1b;
  line-height: 19px;
  padding: 2px 4px;
  margin-left: 10px;
}

.f {
  display: flex;
}

.yc {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 210px;
}

.yy {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}</style>
